/*******************************
         Site Overrides
*******************************/

.ui.vertical.menu .active.item {
  color: #4178d8;
  font-weight: bold;
}

.ui.secondary.pointing.menu .active.item {
    border-width: 3px;
}

.ui.secondary.pointing.menu {
  padding-left: 2.5rem;
}

.ui.secondary.pointing.menu .item {
    margin-right: 1rem;
    border-bottom: 3px solid transparent;
}


.ui.text.menu .item {
  font-family: Montserrat;
}
